<template>
  <div>
    <b-card title="Filters" class="mb-0">
      <b-form @submit.prevent>
        <b-row>
          <!-- Period -->
            <b-col cols="12" md="2">
                <b-form-group
                    :label="$t('apps.reportLedger.singular.period')"
                    label-for="period"
                >
                    <v-select
                        class="select-size-sm"
                        v-model="form.period"
                        :options="LOV.period"
                        :reduce="field => field.value"
                        @input="handlePeriod"
                    />
                </b-form-group>
            </b-col>
            <!-- Date Start -->
            <b-col cols="12" md="2">
              <b-form-group
                  :label="$t('apps.reportLedger.singular.dateStart')"
                  label-for="date-start"
              >
                <flat-pickr
                    id="date-start"
                    :config="dateFormat"
                    v-model="form.dateStart"
                    class="form-control form-control-sm"
                />
              </b-form-group>
            </b-col>
            <!-- Date End -->
               <b-col cols="12" md="2">
                <b-form-group
                    :label="$t('apps.reportLedger.singular.dateEnd')"
                    label-for="date-start"
                >
                    <flat-pickr
                        id="date-end"
                        :config="dateFormat"
                        v-model="form.dateEnd"
                        class="form-control form-control-sm"
                    />
                </b-form-group>
              </b-col>
            <!-- branch  -->
            <b-col cols="12" md="4">
                <b-form-group
                  :label="$t('globalSingular.agent')"
                  label-for="agent"
                >
                    <v-select
                        class="select-size-sm"
                        v-model="form.branch"
                        :options="LOV.branchLOV"
                        :reduce="field => field.id"
                        label="label"
                    />
                </b-form-group>
            </b-col>
            <!-- no document  -->
            <b-col cols="12" md="2">
              <b-form-group
                label="No Document"
                label-for="noDocument"
              >
                <b-form-input
                  id="noDocument"
                  size="sm"
                  v-model="form.noDocument"
                  placeholder="No Doc"
                />
              </b-form-group>
            </b-col>
            <!-- search -->
            <b-col cols="12" md="3">
                <b-button block variant="primary" size="sm" @click="getDataSearch(1)" class="mt-2 pd-1">
                  {{ $t('globalActions.find') }}
                </b-button>
            </b-col>
        </b-row>
      </b-form>
    </b-card>
    <div class="mt-1 mb-1 d-flex justify-content-end">
      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>
      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">
          <template #button-content>
            <feather-icon icon="DownloadIcon"/>
            {{ $t('globalActions.export') }}
          </template>
          <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
          <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>
        </b-dropdown>
      </b-button-group>
    </div>
    <b-card no-body id="pdfDom">
      <!-- judul header  -->
      <b-card-body class="text-center">
        <b-card-title>{{ companyName }}</b-card-title>
        <b-card-sub-title>
          <div>{{ moduleName }}</div>
          <div class="mt-sm-1">{{ periodReportText }}</div>
        </b-card-sub-title>
      </b-card-body>
      <div v-if="loading" class="my-2 text-center text-primary d-flex flex-column justify-content-center">
            <div class="mb-1 text">
              <b-spinner class="align-middle"/>
            </div>
            <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
      </div>
      <!-- table  -->
      <b-table-simple id="ch-checkpoint-table" bordered responsive>
        <b-thead head-variant="dark">
          <!-- BEGIN::Header level 1 -->
          <b-tr>
            <!-- <b-th rowspan="2" class="text-center align-middle">No. </b-th> -->
            <b-th rowspan="2" class="text-center align-middle">AWB No.</b-th>
            <b-th rowspan="2" class="text-center align-middle">AWB Origin </b-th>
            <b-th rowspan="2" class="text-center align-middle">Product Service</b-th>
            <b-th rowspan="2" class="text-center align-middle">Kilo</b-th>
            <b-th rowspan="2" class="text-center align-middle">KOli</b-th>
            <b-th colspan="4" class="text-center align-middle">Delivery Fee (SD/RB)</b-th>
            <b-th colspan="6" class="text-center align-middle">Forward (LF/SF)</b-th>
            <b-th colspan="4" class="text-center align-middle">Jasa Gudang (IM)</b-th>
            <b-th colspan="4" class="text-center align-middle">Transit (IM)</b-th>
            <b-th colspan="5" class="text-center align-middle">Missing Checkpoint(MC)</b-th>
            <b-th rowspan="2" class="text-center align-middle">Total</b-th>
            <b-th rowspan="2" class="text-center align-middle">Catatan</b-th>
          </b-tr>
          <!-- END::Header level 1 -->

          <!-- BEGIN::Header level 2 -->
          <b-tr>
            <!-- BEGIN::Header level 2, Delivery Fee (SD/RB) -->
            <b-th class="text-center">CP Origin</b-th>
            <b-th class="text-center">CP Dest</b-th>
            <b-th class="text-right">Basic Price</b-th>
            <b-th class="text-right">Subtotal</b-th>
            <!-- END::Header level 2, Delivery Fee (SD/RB) -->

            <!-- BEGIN::Header level 2, Forward (LF/SF) -->
            <b-th class="text-center">CP Origin</b-th>
            <b-th class="text-center">CP Dest</b-th>
            <b-th class="text-center">CP AR</b-th>
            <b-th class="text-right">Basic Price1</b-th>
            <b-th class="text-right">Basic Price 2</b-th>
            <b-th class="text-right">Subtotal</b-th>
            <!-- END::Header level 2, Forward (LF/SF) -->

            <!-- BEGIN::Header level 2, Jasa Gudang (IM) -->
            <b-th class="text-center">CP Origin</b-th>
            <b-th class="text-center">CP Dest</b-th>
            <b-th class="text-right">APO Basic Charge</b-th>
            <b-th class="text-right">Subtotal</b-th>
            <!-- END::Header level 2, Jasa Gudang (IM) -->

            <!-- BEGIN::Header level 2, Transit (IM) -->
            <b-th class="text-center">CP Origin</b-th>
            <b-th class="text-center">CP Dest</b-th>
            <b-th class="text-right">Basic Price</b-th>
            <b-th class="text-right">Subtotal</b-th>
            <!-- END::Header level 2, Transit (IM) -->

            <!-- BEGIN::Header level 2, Missing Checkpoint(MC) -->
            <b-th class="text-center">CP Origin</b-th>
            <b-th class="text-center">CP Dest</b-th>
            <b-th class="text-center">Origin AWB</b-th>
            <b-th class="text-right">Basic Price</b-th>
            <b-th class="text-right">Subtotal</b-th>
            <!-- END::Header level 2, Missing Checkpoint(MC) -->
          </b-tr>
          <!-- END::Header level 2 -->
        </b-thead>
        <b-tbody>
          <b-tr v-for="(prop) in itemLists" :key="prop.no" id="itemList">
            <!-- <b-td class="text-center">{{ prop.no }}</b-td> -->
            <b-td sticky-column>
              <b-link
                :to="{ name: 'apps-report-tracking-resi', params: { id: prop.awb_no }}"
                class="font-weight-bolder"
              >
                <span class="d-block text-nowrap text-left">
                  {{ prop.awb_no }}
                </span>
              </b-link>
            </b-td>
            <b-td class="text-center">{{ prop.awb_origin }}</b-td>
            <b-td class="text-center">{{ prop.service }}</b-td>
            <b-td class="text-center">{{ prop.kilo }}</b-td>
            <b-td class="text-center">{{ prop.koli }}</b-td>

            <!-- BEGIN::Table body, Delivery Fee (SD/RB) -->
            <b-td class="text-center">{{ prop.cp_origin_delivery }}</b-td>
            <b-td class="text-center">{{ prop.cp_dest }}</b-td>
            <b-td class="text-right">{{ formatCurrency(prop.basic_price, false) }}</b-td>
            <b-td class="text-right">{{ formatCurrency(prop.subtotal, false) }}</b-td>
            <!-- END::Table body, Delivery Fee (SD/RB) -->

            <!-- BEGIN::Table body, Forward (LF/SF) -->
            <b-td class="text-center">{{ prop.cp_origin_forward }}</b-td>
            <b-td class="text-center">{{ prop.cp_dest_forward }}</b-td>
            <b-td class="text-center">{{ prop.cp_ar_forward }}</b-td>
            <b-td class="text-right">{{ formatCurrency(prop.basic_price1_forward, false) }}</b-td>
            <b-td class="text-right">{{ formatCurrency(prop.basic_price2_forward, false) }}</b-td>
            <b-td class="text-right">{{ formatCurrency(prop.subtotal_forward, false) }}</b-td>
            <!-- END::Table body, Forward (LF/SF) -->

            <!-- BEGIN::Table body, Jasa Gudang (IM) -->
            <b-td class="text-center">{{ prop.cp_origin_jasa_gudang }}</b-td>
            <b-td class="text-center">{{ prop.cp_dest_jasa_gudang }}</b-td>
            <b-td class="text-right">{{ formatCurrency(prop.basic_price_jasa_gudang, false) }}</b-td>
            <b-td class="text-right">{{ formatCurrency(prop.subtotal_jasa_gudang, false) }}</b-td>
            <!-- END::Table body, Jasa Gudang (IM) -->

            <!-- BEGIN::Table body, Transit (IM) -->
            <b-td class="text-center">{{ prop.cp_origin_transit }}</b-td>
            <b-td class="text-center">{{ prop.cp_dest_transit }}</b-td>
            <b-td class="text-right">{{ formatCurrency(prop.basic_price_transit, false) }}</b-td>
            <b-td class="text-right">{{ formatCurrency(prop.subtotal_transit, false) }}</b-td>
            <!-- END::Table body, Transit (IM) -->

            <!-- BEGIN::Table body, Missing Checkpoint(MC) -->
            <b-td class="text-center">{{ prop.cp_origin_missing }}</b-td>
            <b-td class="text-center">{{ prop.cp_dest_missing }}</b-td>
            <b-td class="text-center">{{ prop.origin_awb_missing }}</b-td>
            <b-td class="text-right">{{ formatCurrency(prop.basic_price_missing, false) }}</b-td>
            <b-td class="text-right">{{ formatCurrency(prop.subtotal_missing, false) }}</b-td>
            <!-- END::Table body, Missing Checkpoint(MC) -->

            <b-td class="text-right">{{ formatCurrency(
              parseFloat(prop.subtotal) +
              parseFloat(prop.subtotal_forward) +
              parseFloat(prop.subtotal_jasa_gudang) +
              parseFloat(prop.subtotal_transit) +
              parseFloat(prop.subtotal_missing), false) 
            }}</b-td>
            <b-td class="text-center">{{ prop.catatan }}</b-td>

          </b-tr>
        </b-tbody>
      </b-table-simple>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('globalSingular.showing') }} {{ dataMeta.from }} {{ $t('globalSingular.to') }} {{ dataMeta.to }} {{ $t('globalSingular.of') }} {{ dataMeta.of }} {{ $t('globalSingular.entries') }}</span>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            per-page=15
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="getDataSearch($event)"
          >
            <template #prev-text >
              <feather-icon
                @click="getDataSearch(currentPage)"
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                @click="getDataSearch(currentPage)"
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
    </b-card-body>

    </b-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ref, onMounted } from '@vue/composition-api'
import useHttp from '@/comp-functions/useHttp'
import useListReportTable from '@/comp-functions/useListReportTable'
import { format, getMonth, startOfMonth, endOfMonth, subMonths, addMonths, subYears, parse } from 'date-fns'
import flatPickr from 'vue-flatpickr-component'
import { formatCurrency, unformatNumber } from '@/utils/formatter'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

import {
    BTh,
    BTr,
    BTd,
    BThead,
    BCard,
    BForm,
    BRow,
    BCol,
    BLink,
    BTbody,
    BSpinner,
    BFormGroup,
    BButton,
    BFormInput,
    BButtonGroup,
    BDropdown,
    BCardBody,
    BCardTitle,
    BPagination,
    BTableSimple,
    BCardSubTitle,
    BDropdownItemButton
} from 'bootstrap-vue'

export default {
  components: {
    BTh,
    BTr,
    BTd,
    BThead,
    BCard,
    BForm,
    BLink,
    flatPickr,
    BRow,
    BCol,
    BTbody,
    BFormGroup,
    BButton,
    BFormInput,
    vSelect,
    BButtonGroup,
    BDropdown,
    BSpinner,
    BCardBody,
    BCardTitle,
    BPagination,
    BTableSimple,
    BCardSubTitle,
    BDropdownItemButton
  },
  directives: {
    Ripple,
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    },
    getBranchLabel() {
      return (this.LOV.branchLOV.find(item =>
        item.id === this.form.branch) ?? {label: ''}).label
    },
    moduleName () {
      return `${this.$t('apps.reportCH.moduleName')}${
        this.form.branch ? ` - ${this.getBranchLabel}` : ''
      }`
    }
  },
  setup () {
    const user = store.state.user
    const { $get } = useHttp()
    let loading = ref(false)

    const {
      isBusy,
      itemLists,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListReportTable({ url: false })

    const form = ref({
      branch: 'JKT',
      noDocument: '',
      period: 'thisMonth',
      dateStart: '',
      dateEnd: ''
    })

    const LOV = ref({
      branchLOV: [],
      period: [
        { value: 'thisMonth', label: 'This Month' },
        { value: 'previousMonth', label: 'Previous Month' },
        { value: 'thisYear', label: 'This Year' },
        { value: 'previousYear', label: 'Previous Year' }
      ]
    })

    const periodReportText = ref()
    const dateFormat = ref({ dateFormat: 'd/m/Y', allowInput: true })
    const tableColumns = [
      // { key: 'no', label: 'no', thClass:'', tdClass: '' },
      { key: 'awb_no', label: 'AWB no', thClass:'', tdClass: '' },
      { key: 'awb_origin', label: 'AWB no', thClass:'', tdClass: '' },
      { key: 'service', label: 'Product Service', thClass:'', tdClass: '' },
      { key: 'kilo', label: 'Kilo', thClass:'', tdClass: '' },
      { key: 'koli', label: 'Koli', thClass:'', tdClass: '' },

      { key: 'cp_origin_delivery', label: 'CP Origin', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_dest', label: 'CP Dest', thClass:'text-right', tdClass: 'text-right' },
      { key: 'basic_price', label: 'Basic Price', thClass:'text-right', tdClass: 'text-right' },
      { key: 'subtotal', label: 'Subtotal', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_origin_forward', label: 'CP Origin', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_dest_forward', label: 'CP Dest', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_ar_forward', label: 'CP AR', thClass:'text-center', tdClass: 'text-center' },
      { key: 'basic_price1_forward', label: 'Basic Price1', thClass:'text-right', tdClass: 'text-right' },
      { key: 'basic_price2_forward', label: 'Basic Price', thClass:'text-right', tdClass: 'text-right' },
      { key: 'subtotal_forward', label: 'Subtotal', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_origin_jasa_gudang', label: 'CP Origin', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_dest_jasa_gudang', label: 'CP Dest', thClass:'text-right', tdClass: 'text-right' },
      { key: 'basic_price_jasa_gudang', label: 'APO Basic', thClass:'text-right', tdClass: 'text-right' },
      { key: 'subtotal_jasa_gudang', label: 'Subtotal', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_origin_transit', label: 'CP Origin', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_dest_transit', label: 'CP Dest', thClass:'text-right', tdClass: 'text-right' },
      { key: 'basic_price_transit', label: 'Basic Price', thClass:'text-right', tdClass: 'text-right' },
      { key: 'subtotal_transit', label: 'Subtotal', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_origin_missing', label: 'CP Origin', thClass:'text-right', tdClass: 'text-right' },
      { key: 'cp_dest_missing', label: 'CP Dest', thClass:'text-right', tdClass: 'text-right' },
      { key: 'origin_awb_missing', label: 'Origin AWB', thClass:'text-right', tdClass: 'text-right' },
      { key: 'basic_price_missing', label: 'Basic Price', thClass:'text-right', tdClass: 'text-right' },
      { key: 'subtotal_missing', label: 'Subtotal', thClass:'text-right', tdClass: 'text-right' },

      { key: 'total_all', label: 'total', thClass:'', tdClass: '' },
      { key: 'catatan', label: 'catatan', thClass:'', tdClass: '' },

    ]

    const updatePeriodText = () => {
      const dateStart = parse(form.value.dateStart, 'dd/MM/yyyy', new Date())
      const dateEnd = parse(form.value.dateEnd, 'dd/MM/yyyy', new Date())
      const formatStartDate = format(dateStart, 'd MMMM yyyy')
      const formatEndDate = format(dateEnd, 'd MMMM yyyy')
      const text = `${formatStartDate} to ${formatEndDate}`
      periodReportText.value = text
      return text
    }

    const handlePeriod = () => {
      const formatString = 'dd/MM/yyyy'
      const thisMonth = new Date()
      const whatMonth = getMonth(thisMonth) + 1
      const previousMonth = subMonths(thisMonth, 1)
      const firstMonthOfYear = subMonths(thisMonth, whatMonth - 1)
      const lastMonthOfYear = addMonths(thisMonth, (whatMonth - 12) * -1)
      const firstMonthOfPreviousYear = subYears(firstMonthOfYear, 1)
      const lastMonthOfPreviousYear = subYears(lastMonthOfYear, 1)

      if (form.value.period === 'thisMonth') {
        form.value.dateStart = format(startOfMonth(thisMonth), formatString)
        form.value.dateEnd = format(endOfMonth(thisMonth), formatString)
      } else if (form.value.period === 'previousMonth') {
        form.value.dateStart = format(startOfMonth(previousMonth), formatString)
        form.value.dateEnd = format(endOfMonth(previousMonth), formatString)
      } else if (form.value.period === 'thisYear') {
        form.value.dateStart = format(startOfMonth(firstMonthOfYear), formatString)
        form.value.dateEnd = format(endOfMonth(lastMonthOfYear), formatString)
      } else {
        form.value.dateStart = format(startOfMonth(firstMonthOfPreviousYear), formatString)
        form.value.dateEnd = format(endOfMonth(lastMonthOfPreviousYear), formatString)
      }
    }


    const getOrgBranches = async () => {
      const { data } = await $get({ url: `value/getBranchOps`})
      LOV.value.branchLOV = data

      if (!user.isNational) {
        form.value.branch = data[0].id
      }
    }

    let currentPage  = ref(1)
    let perPage      = ref(50)

    onMounted(async () => {
      handlePeriod()
      getOrgBranches()
      updatePeriodText()
    })

    const page               = ref(0)
    const totalPageRight     = ref(true)
    const totalPageLeft      = ref(true)
    const dataFrompage       = ref(1)
    const dataExcel          = ref([])

    const getDataSearch = async (angkaPage) => {
    updatePeriodText()
    loading.value = true
      if(angkaPage == 0){
        dataFrompage.value = angkaPage + 1
      }else{
        dataFrompage.value = angkaPage
      }

      const params =
      [
        `dateStart=${form.value.dateStart}`,
        `dateEnd=${form.value.dateEnd}`,
        `branch=${form.value.branch}`,
        `noDocument=${form.value.noDocument}`,
        `angka=${angkaPage}`,
      ]
      let getTotal  = await $get({ url: `reports/ch-check-point/countDataChAp?${ params.join('&') }`})
      getTotal      = parseInt(getTotal.data) - 1

      if(getTotal == page.value){
        totalPageRight.value = true
      }else{
        totalPageRight.value = false
      }

      if(page.value <= 0){
        totalPageLeft.value = true
      }else{
        totalPageLeft.value = false
      }

      const url = `reports/ch-check-point?${ params.join('&') }`
      await fetchLists(url, getTotal, dataFrompage.value)
      loading.value = false
      dataExcel.value = await $get({ url: `reports/ch-check-point-excel?${ params.join('&') }` })
    }

    return {
        dataExcel,
        totalPageLeft,
        totalPageRight,
        getDataSearch,
        loading,
        form,
        LOV,
        isBusy,
        itemLists,
        handlePeriod,
        dateFormat,
        perPage,
        currentPage,
        totalList,
        dataMeta,
        perPageOptions,
        searchQuery,
        isSortDirDesc,
        refListTable,
        statusFilter,
        fetchLists,
        tableColumns,
        periodReportText,
        formatCurrency
    }
  },
  methods: {
    getReport () {
      this.loading = true
      const params = [
        `dateStart=${this.form.dateStart}`,
        `dateEnd=${this.form.dateEnd}`,
        `branch=${this.form.branch}`,
        `noDocument=${this.form.noDocument}`
      ]
      const url = `reports/ch-check-point?${ params.join('&') }`

      this.fetchLists(url)
      this.periodReportText = `${this.form.branch} `
      this.loading = false
    },

    print () {
      window.print()
    },

    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },

    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.dataExcel.data

        for (let index = 0; index < list.length; index++) {
          delete list[index].catatan_2
          delete list[index].del_origin
        }

        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)

        const data = this.formatJson(headerVal, list).map((row) => {
          return row.map((cell, index) => {
            if (index === 0) {
              return cell;
            }
            return unformatNumber(cell);
          });
        });

        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
            [this.$t('apps.reportCH.singular.report'), '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
            ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
            ['AWB No', 'Origin', 'Product Service', 'Kilo', 'Koli', 'Delivery Fee (SD/RB)', '', '', '', 'Forward (LF/SF)', '', '', '', '', '', 'Jasa Gudang (IM)', '', '', '', 'Transit (IM)', '', '', '', 'Missing Checkpoint (MC)', '', '', '', '', 'Total', 'Catatan'],
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 5, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 5, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 5, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 5, r: 3 } },
            { s: { r: 4, c: 5 }, e: { r: 4, c: 8 } },
            { s: { r: 4, c: 9 }, e: { r: 4, c: 14 } },
            { s: { r: 4, c: 15 }, e: { r: 4, c: 18 } },
            { s: { r: 4, c: 19 }, e: { r: 4, c: 22 } },
            { s: { r: 4, c: 23 }, e: { r: 4, c: 27 } },
            { s: { r: 4, c: 0 }, e: { r: 5, c: 0 } },
            { s: { r: 4, c: 1 }, e: { r: 5, c: 1 } },
            { s: { r: 4, c: 2 }, e: { r: 5, c: 2 } },
            { s: { r: 4, c: 3 }, e: { r: 5, c: 3 } },
            { s: { r: 4, c: 4 }, e: { r: 5, c: 4 } },
            { s: { r: 4, c: 28 }, e: { r: 5, c: 28 } },
            { s: { r: 4, c: 29 }, e: { r: 5, c: 29 } },
          ],
          filename: `${this.moduleName} ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },

    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .dropdown-menu .dropdown-item {
    width: 100%;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }
    }
  }

  @page {
    size: auto;
  }

  [dir] .table .thead-dark th {
    border-color: white;
  }
</style>
